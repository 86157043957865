<template>
  <div class="hk_page_routerview">
    <div class="hk_page_title hk_page_title_tabs">
      <div class="hk_page_title_left">
        <div class="title_txt">小红书商品</div>
        <el-tabs class="title_tabs" v-model="activeName">
          <el-tab-pane label="上架中" name="ON_SELL"></el-tab-pane>
          <el-tab-pane label="已下架" name="OFF_SELL"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="hk_page_title_right">
        <el-button type="primary" size="small" plain @click="ifShowAdd=true">添加商品</el-button>
      </div>

    </div>

    <div class="hk_page_wrap hk_page_wrap_tabs">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            搜索：<el-input debounce size="small" clearable placeholder="商品名称" v-model="searchName">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>

          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{totalNum}}</span> 个商品
        </div>

        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="商品封面" width="110px">
            <template slot-scope="scope">
              <img class="hk-product-tb-img" :src="scope.row.productImg" alt="">
            </template>
          </el-table-column>
          <el-table-column label="商品名称" width="260px">
            <template slot-scope="scope">
              <span>{{scope.row.productName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="应付金额" width="130px">
            <template slot-scope="scope">
              {{ scope.row.originalPrice/100 }}
            </template>
          </el-table-column>
          <el-table-column label="折扣">
            <template slot-scope="scope">
              {{ (scope.row.sellPrice==scope.row.originalPrice)?'--':(scope.row.sellPrice*10/scope.row.originalPrice) }}
            </template>
          </el-table-column>
          <el-table-column label="实付金额" width="120px">
            <template slot-scope="scope">
              {{ scope.row.sellPrice/100 }}
            </template>
          </el-table-column>

          <template v-if="activeName=='OFF_SELL'">
            <el-table-column label="商品状态" width="120px">
              已下架
            </el-table-column>
          </template>
          <el-table-column label="创建人" width="180px">
            <template slot-scope="scope">
              {{ scope.row.creatorName ||'小红书'}} <br>
              {{ scope.row.createTime }}
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180px" fixed="right">
            <template slot-scope="scope">
              <template v-if="activeName=='ON_SELL'">
                <span class="hk-table-option hk-table-option-first" @click="seeDetailFun(scope.row)">查看</span>
                <!-- <span class="hk-table-option">下架</span> -->
                <el-popover placement="top-end" width="280" trigger="click" :ref="'popover-'+ scope.row.id ">
                  <!-- v-model="scope.row.ifDelLinkPop" -->
                  <p class="popover-option"><i class="el-icon-warning hk-font-highlight" style="font-size: 16px;"></i>&nbsp;确定下架吗？</p>
                  <div style="text-align: right; margin: 20px 0 0">
                    <el-button size="mini" @click="cancelDownUpFun(scope.row.id)">取消</el-button>
                    <el-button type="primary" size="mini" @click="confirmDownUpFun(scope.row.id,'OFF_SELL')">下架</el-button>
                  </div>
                  <span class="hk-table-option" slot="reference">下架</span>
                </el-popover>
              </template>
              <template v-else>
                <span class="hk-table-option hk-table-option-first" @click="showEditDailog(scope.row)">编辑</span>
                <el-popover placement="top-end" width="280" trigger="click" :ref="'popover-'+ scope.row.id ">
                  <!-- v-model="scope.row.ifDelLinkPop" -->
                  <p class="popover-option"><i class="el-icon-warning hk-font-highlight" style="font-size: 16px;"></i>&nbsp;确认上架吗？</p>
                  <div style="text-align: right; margin: 20px 0 0">
                    <el-button size="mini" @click="cancelDownUpFun(scope.row.id)">取消</el-button>
                    <el-button type="primary" size="mini" @click="confirmDownUpFun(scope.row.id,'ON_SELL')">上架</el-button>
                  </div>
                  <span class="hk-table-option" slot="reference">上架</span>
                </el-popover>

                <el-popover placement="top-end" width="280" trigger="click" :ref="'popover-del-'+ scope.row.id ">
                  <!-- v-model="scope.row.ifDelLinkPop" -->
                  <p class="popover-option"><i class="el-icon-warning hk-font-highlight" style="font-size: 16px;"></i>&nbsp;确认删除吗？</p>
                  <div style="text-align: right; margin: 20px 0 0">
                    <el-button size="mini" @click="cancelDeleteFun(scope.row.id)">取消</el-button>
                    <el-button type="primary" size="mini" @click="confirmDeleteFun(scope.row.id,'ON_SELL')">删除</el-button>
                  </div>
                  <span class="hk-table-option" slot="reference">删除</span>
                </el-popover>
                <!-- <span class="hk-table-option" @click="deleteProductFun(scope.row.id)">删除</span> -->
              </template>
            </template>
          </el-table-column>
          <div slot="empty">
            <p>暂无商品！</p>
          </div>
        </el-table>
        <div class="hk_pagination" v-if="totalNum>0">
          <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20, 50, 100]" layout="total,sizes,prev, pager, next" :total="totalNum">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 添加商品 -->
    <el-dialog :title="editId?'编辑':'添加商品'" :visible.sync="ifShowAdd" :modal-append-to-body="false" width="60%">
      <el-form label-width="94px" size="small" :rules="addRules" ref="addForm" :model="addForm" v-loading="ifShowUploading">
        <el-form-item label="商品名称：" prop="productName">
          <el-input v-model="addForm.productName" class="hk-ipt-number" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="选择班级：" prop="classId">
          <el-select placeholder="请选择" v-model="selectClassObj.courseName" @change="classChangeFun" style="width:100%" :disabled="editId?true:false">
            <el-option :label="item.courseName+' 【'+productTypeEnumObj[item.courseType]+'】'" :value="item" :key="item.id" v-for="item in classList"></el-option>
          </el-select>
          <!-- 添加的时候不显示 学生人数 和 课次，因为接口没有返回数据 -->
          <div class="hk-font-highlight" style="font-size:12px;line-height:16px;" v-if="editId">学生人数：{{selectClassObj.studentNum}} 课次：{{selectClassObj.lessonNum}}</div>
        </el-form-item>
        <el-form-item label="应付金额：" prop="productPrice">
          <el-input v-model.number="addForm.productPrice" class="hk-ipt-number" placeholder="请输入正整数" type="number"></el-input>
        </el-form-item>
        <el-form-item label="折扣：" prop="discount">
          <div><el-input v-model="addForm.discount" class="hk-ipt-number" placeholder="请输入0.1~9.9范围内的折扣" type="number"></el-input>
          </div>
          <div v-if="addForm.productPrice">实付金额：<span class="hk-font-highlight">￥ {{ shifuNum }}</span></div>
        </el-form-item>

        <el-form-item label="商品封面：" prop="productImg">
          <div class="product-img-block">
            <div class="course-img" v-if="addForm.productImg==''">
              <input v-if="addForm.productImg==''" class="ipt-file" id="header-img-file" type="file" accept="image/*" @change="changeUpload('header-img-file','header')">
              <img :src="addForm.productImg" alt="" v-if="addForm.productImg!=''">
              <template v-if="addForm.productImg==''">
                <div class="iconfont icon-jiahao"></div>
                <p>上传图片</p>
              </template>
            </div>
            <div class="course-img" style="border:0;" v-if="addForm.productImg !=''">
              <img :src="addForm.productImg" alt="" v-if="addForm.productImg!=''" @click="previewImg(addForm.productImg)">
            </div>
            <div class="course-item">
              <span class="refresh-file-box">
                <template v-if="addForm.productImg !=''">
                  <input class="ipt-file" id="header-img-file-refresh" type="file" accept="image/*" @change="changeUpload('header-img-file-refresh','header')">
                  <i class="iconfont icon-again refresh-icon"></i>
                </template>
              </span>
            </div>
            <div class="course-item">

              1.封面图片尺寸为240x240 <br>2.封面图片大小不超过5MB <br>3.封面图片格式支持JPG，PNG，GIF
            </div>
          </div>
        </el-form-item>
        <el-form-item label="详情：" prop="productDetailImg">

          <div class="product-img-block">
            <div class="course-img" v-if="addForm.productDetailImg==''">
              <input v-if="addForm.productDetailImg==''" class="ipt-file" id="desc-img-file" type="file" accept="image/*" @change="changeUpload('desc-img-file','desc')">
              <img :src="addForm.productDetailImg" alt="" v-if="addForm.productDetailImg!=''">
              <template v-if="addForm.productDetailImg==''">
                <div class="iconfont icon-jiahao"></div>
                <p>上传图片</p>
              </template>
            </div>
            <div class="course-img" style="border:0;" v-if="addForm.productDetailImg !=''">
              <img :src="addForm.productDetailImg" alt="" v-if="addForm.productDetailImg!=''" @click="previewImg(addForm.productDetailImg)">
            </div>
            <div class="course-item">
              <span class="refresh-file-box">
                <template v-if="addForm.productDetailImg !=''">
                  <input class="ipt-file" id="desc-img-file-refresh" type="file" accept="image/*" @change="changeUpload('desc-img-file-refresh','desc')">
                  <i class="iconfont icon-again refresh-icon"></i>
                </template>
              </span>
            </div>
            <div class="course-item">
              1.封面图片尺寸为750x∞<br>2.封面图片大小不超过5MB<br>3.封面图片格式支持JPG，JPEG，PNG
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAddFun" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmAddFun('addForm')" size="small">保 存</el-button>
      </span>
    </el-dialog>

    <!-- 查看详情 -->
    <el-dialog title="查看详情" :visible.sync="ifShowDetail" :modal-append-to-body="false" width="60%" @close="closeDetailDialog">
      <el-form label-width="94px" size="small">
        <el-form-item label="商品名称：">
          {{detailRow.productName}}
        </el-form-item>
        <el-form-item label="选择班级：">
          {{detailRow.keHkCourseDO? detailRow.keHkCourseDO.courseName:''}}
        </el-form-item>
        <el-form-item label="应付金额：">
          {{detailRow.originalPrice/100}}
        </el-form-item>
        <el-form-item label="折扣：" prop="discount">
          {{ detailRow.sellPrice*10/detailRow.originalPrice }}
          <div>实付金额：<span class="hk-font-highlight">￥ {{ detailRow.sellPrice/100 }}</span></div>
        </el-form-item>

        <el-form-item label="商品封面：" prop="productImg">
          <div class="product-img-block">
            <div class="course-img">
              <img :src="detailRow.productImg" alt="" v-if="detailRow.productImg!=''">
            </div>

          </div>
        </el-form-item>
        <el-form-item label="详情：">
          <div class="product-img-block">
            <div class="course-img">
              <img :src="detailRow.productDetailImg" alt="" v-if="detailRow.productDetailImg!=''">
            </div>

          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--预览图片-->
    <el-dialog title="预览" :modal-append-to-body='false' :visible.sync="imgPreviewFlag" width="450px">
      <div class="dialog-img">
        <img :src="imgPreviewUrl" alt="">
      </div>
    </el-dialog>

  </div>
</template>

<script>
// import { GetProductListApi } from '../../request/api';
import { validatePositiveInteger, validateDiscount } from '../../utils/validate';
import { ProductTypeEnum } from '../../utils/enums';
export default {
  data() {
    return {
      activeName: 'ON_SELL', //状态

      searchName: "", //商品名称
      searchType: "EXERCISE",
      tableData: [], //列表中显示的数据(搜索后,只显示符合条件)
      pageSize: 10, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      ifShowAdd: false, //true-显示弹窗 debugger
      ifShowUploading: false, //上传图片的loading
      editId: '', //编辑商品时的商品id
      addForm: {
        productName: '', //商品名称
        classId: '', //班级id
        productPrice: '', //商品价格，原价，必填
        discount: '', //折扣额度，不打折不输入
        productImg: 'https://oss.ke.live/images/exercise_banner.png',//商品封面，必填
        productDetailImg: 'https://oss.ke.live/images/exercise_detail.jpg', //商品详情图，必填

      },
      // yingfuNum:'', //应付金额
      shifuNum: '', // 实付金额
      // zengsongNum:'', //赠送额度
      classList: [],//班级列表
      selectClassObj: {}, //选择的班级信息

      addRules: {
        productName: [
          { required: true, message: '请输入', trigger: 'blur' }
        ],
        classId: [
          { required: true, message: '请选择', trigger: 'blur' }
        ],
        productPrice: [
          { required: true, validator: validatePositiveInteger, trigger: 'blur', }, //自定义验证
        ],
        discount: [
          { validator: validateDiscount, trigger: 'change' }
        ],
        productImg: [
          { required: true, message: '请上传', trigger: 'blur' }
        ],
        productDetailImg: [
          { required: true, message: '请上传', trigger: 'blur' }
        ]
      },


      productTypeEnumObj: ProductTypeEnum,


      ifShowDetail: false, //查看详情弹窗 是否显示
      detailRow: {}, //详情对应的商品信息

      imgPreviewFlag: false, //预览图片的弹窗是否显示
      imgPreviewUrl: '',//预览的图片地址

    }
  },
  watch: {
    'addForm.productPrice': {
      handler(val, old) {
        if (val > 0) {
          this.countDiscountFun()
        }
      },
      immediate: true,
      deep: true

    },
    'addForm.discount': {
      handler(val, old) {
        if (this.addForm.productPrice) {
          //计算折扣
          this.countDiscountFun()
        }

      },
      immediate: true,
      deep: true
    },
    activeName(val, old) { //上下架状态
      this.searchFun() //重新从第一页开始
      // this.getProductFun();
    }
  },

  mounted() {
    let that = this;
    that.getProductFun()
  },
  methods: {
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getProductFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getProductFun();
    },

    //搜索
    searchFun() {
      this.curPage = 1
      this.tableData = []
      this.totalNum = 0
      this.getProductFun()
    },

    //获取班课商品列表
    getProductFun() {
      let that = this;
      let param = {
        pageNum: that.curPage,
        pageSize: that.pageSize,
        productStatus: that.activeName //商品状态，上下架

      };
      if (that.searchName && that.searchName.trim().length > 0) {
        param.productName = that.searchName.trim()
      }
      param.productType = 'XIAO_HONG_SHU'
      that.request({
        method: 'post',
        url: '/manage/product/listProduct',
        param: param,
        success: function (result) {
          if (result.code == 200) {

            let data = result.result;
            that.tableData = data.list;
            that.totalNum = Number(data.total);
          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取失败。')
          console.log(error, '-----获取失败')
        }
      })


      // GetProductListApi().then(res=>{
      //     debugger
      //     console.log(res,'-----获取商品列表')
      // })    
    },

    //计算折扣信息，前提条件：数据格式都正确
    countDiscountFun() {
      let that = this;
      let yuan = that.addForm.productPrice, dis = that.addForm.discount
      let yingfu = yuan, shifu = yuan, zengsong = 0;
      if (dis && dis.toString().length < 4) {
        shifu = that.priceYuanToFen(yingfu) * dis / 10 / 100
        zengsong = (that.priceYuanToFen(yingfu) - that.priceYuanToFen(shifu)) / 100
      }
      // that.yingfuNum = yingfu
      that.shifuNum = shifu
      // that.zengsongNum = zengsong

    },

    //获取上传的文件名称
    getFileName(file) {
      var pos = file.lastIndexOf("\\");
      return file.substring(pos + 1);
    },
    //上传图片
    changeUpload(eleId, imgType) {
      let that = this;
      that.ifShowUploading = true;

      let params = {
        ossPath: 'PRODUCT',
        fileName: that.getFileName(document.getElementById(eleId).value)
      }
      let file = document.getElementById(eleId).files[0];

      that.request({
        method: 'post',
        url: '/tool/getOauthUrl',
        param: params,
        success: function (res) {

          var fileUrl = res.result; //返回的图片地址，和xhr.responseURL一样
          // 2. 上传文件
          var xhr = new XMLHttpRequest();
          xhr.open("PUT", fileUrl, true);
          // 注意不要传入Content-Type，否则会出现签名错误
          xhr.setRequestHeader('Content-Type', '');
          xhr.onload = function () {
            // 请求结束后，在此处编写处理代码。
            if (xhr.readyState == 4) {
              let backResponse = xhr.responseURL; //直接返回的图片url,图片处理域名并截取？之前地址
              // https://kelivemanage.oss-cn-beijing.aliyuncs.com/pre/product/2024/20240919115613101.jpg?Expires=1726718474&OSSAccessKeyId=LTAI5tBc2wB3JcdbJhrXgpXC&Signature=Us%2FGfiJ19QshtXQHUuqelpsK1P8%3D

              let endIdx = backResponse.indexOf('?')
              let imgUrl = backResponse.substring(0, endIdx)
              let newUrl = imgUrl.replace('//kelivemanage.oss-cn-beijing.aliyuncs.com', '//oss.ke.live')
              console.log(newUrl)

              if (imgType == 'desc') {
                //描述
                that.$set(that.addForm, 'productDetailImg', newUrl)
                // that.addForm.productDetailImg = newUrl;
              } else {
                //头图
                that.$set(that.addForm, 'productImg', newUrl)
                // that.addForm.productImg = newUrl;
              }
              that.ifShowUploading = false;
              document.getElementById(eleId).value = '';
            }
          };
          xhr.send(file);

        },
        error: function (error) {
          //服务器异常
          console.log(error);
          that.$message.error('上传失败');
        }
      })

    },

    //切换班级,只有新建的时候，才会触发
    classChangeFun(item) {
      let that = this;
      // that.selectClassObj = Object.assign({},item)
      that.selectClassObj = Object.assign({}, {
        id: item.id,
        courseName: item.courseName,
        courseType: item.courseType
      })
      that.$set(that.addForm, 'classId', item.id) //这里使用id,不是courseId
    },
    //显示编辑的弹窗
    showEditDailog(row) {

      this.editId = row.id
      this.$set(this.addForm, 'productName', row.productName)
      this.$set(this.addForm, 'productType', row.productType)
      this.$set(this.addForm, 'classId', row.refId)
      this.$set(this.addForm, 'productPrice', row.originalPrice / 100)
      this.$set(this.addForm, 'discount', row.sellPrice * 10 / row.originalPrice)
      this.$set(this.addForm, 'productImg', row.productImg)
      this.$set(this.addForm, 'productDetailImg', row.productDetailImg)
      this.selectClassObj = Object.assign({}, row.keHkCourseDO)

      this.ifShowAdd = true
      this.countDiscountFun()
    },

    //弹窗--保存
    confirmAddFun(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            "productName": that.addForm.productName,
            "productType": that.selectClassObj.courseType,//
            "refId": that.addForm.classId,
            "originalPrice": that.priceYuanToFen(that.addForm.productPrice), //商品原价
            "sellPrice": that.priceYuanToFen(that.shifuNum), //，实付金额，单位分
            // "giftAmount":that.priceYuanToFen(that.zengsongNum), //赠送金额，单位分
            "productImg": that.addForm.productImg,
            "productDetailImg": that.addForm.productDetailImg,

          }
          let url = '/manage/product/addProduct'
          if (that.editId) { //编辑
            params.id = that.editId
            url = '/manage/product/updateCourseProduct'
          }
          that.request({
            method: 'post',
            url: url,
            param: params,
            success: function (res) {
              if (res.code == 200) {
                let proMsg = '添加成功'
                if (that.editId) {
                  proMsg = '编辑成功'
                }
                that.$message({
                  message: proMsg,
                  type: 'success'
                })

                that.getProductFun()

              } else {
                that.$message.error(res.msg)
              }
              that.cancelAddFun()
            },
            error: function (error) {
              //服务器异常
              console.log('保存出错，' + error);
              that.$message.error(error);
            }
          })
        } else {
          // this.$message('请输入信息111111') //默认 type = info
          this.$message({
            message: '请输入信息',
            type: 'warning'
          })
          console.log('error submit!!');
          return false;
        }
      });

    },
    //弹窗--取消
    cancelAddFun() {
      this.ifShowAdd = false
      //addForm的字段清空；shifuNum那三个也要清空
      this.addForm = Object.assign({}, {
        productName: '', //商品名称
        classId: '', //班级id
        productPrice: '', //商品价格，必填
        discount: '', //折扣额度，不打折不输入
        productImg: '',//商品封面，必填
        productDetailImg: '',
      }),
        this.selectClassObj = Object.assign({}, {})

      // this.yingfuNum = ''
      this.shifuNum = ''
      this.editId = ''
      // this.zengsongNum = ''

    },

    //上下架商品 newStaus--新状态
    confirmDownUpFun(id, newStatus) {
      let that = this;
      let params = {
        id: id,
        productStatus: newStatus
      }
      that.request({
        method: 'post',
        url: '/manage/product/updateCourseProductStatus',
        param: params,
        success: function (res) {

          if (res.code == 200) {

            that.$message({
              message: '操作成功！',
              type: 'success'
            })

            that.getProductFun()

          } else {
            that.$message.error(res.msg)
          }
          that.cancelDownUpFun(id)

        },
        error: function (error) {
          //服务器异常
          console.log('上下架出错，' + error);
          that.$message.error(error);
        }
      })

    },

    //取消上下架
    cancelDownUpFun(id) {
      // console.log(this.$refs['popover-'+id],'----style---',this.$refs)
      this.$refs['popover-' + id].popperElm.style.display = "none"
    },

    //点击“查看详情”
    seeDetailFun(row) {
      this.detailRow = Object.assign({}, row)
      this.ifShowDetail = true
    },

    closeDetailDialog() {
      this.ifShowDetail = false

      this.detailRow = Object.assign({}, {})
    },

    //确认删除商品
    confirmDeleteFun(id) {
      let that = this;
      let params = {
        id: id
      }
      that.request({
        method: 'post',
        url: '/manage/product/delCourseProduct',
        param: params,
        success: function (res) {
          if (res.code == 200) {

            that.$message({
              message: '删除成功！',
              type: 'success'
            })

            that.getProductFun()

          } else {
            that.$message.error(res.msg)
          }
          that.cancelDeleteFun(id)

        },
        error: function (error) {
          //服务器异常
          console.log('删除错误，' + error);
          that.$message.error(error);
        }
      })
    },
    cancelDeleteFun(id) {
      this.$refs['popover-del-' + id].popperElm.style.display = "none"
    },

    //预览图片--针对学生上传的
    previewImg(url) {
      this.imgPreviewFlag = true;
      this.imgPreviewUrl = url;
    },

  }
}
</script>

<style lang="scss" scoped>
.hk_page_wrap {
  .product-tb-img {
    width: 44px;
    height: 44px;
  }
}
.fee-hour-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .fee-hour-item {
    width: 50%;
    text-align: left;
  }
}

//添加商品图片

.product-img-block {
  display: flex;
  align-items: flex-end;

  .course-img {
    width: 104px;
    height: 104px;
    border-radius: 4px;
    border: 1px dashed #e0e0e0;
    text-align: center;
    color: #e0e0e0;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    .ipt-file {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      cursor: pointer;
    }

    .iconfont {
      margin-top: 20px;
      margin-bottom: 12px;
      font-size: 24px;
    }

    p {
      font-size: 16px;
      color: #bdbdbd;
      line-height: 24px;
    }
  }
  .course-item {
    font-size: 12px;
    color: #bdbdbd;
    line-height: 22px;
    margin-left: 16px;

    .refresh-file-box {
      display: block;
      height: 28px;
      position: relative;
      cursor: pointer;
      .ipt-file {
        width: 24px;
        height: 24px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 100;
      }

      .refresh-icon {
        font-size: 24px;
        color: #f08d00;
        cursor: pointer;
      }
    }
  }
}

//预览图片
.dialog-img {
  min-height: 150px;
  max-height: 500px;
  overflow: auto;
  img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
}
</style>